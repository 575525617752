var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.jobList, function (job) {
      return _c(
        "el-card",
        { key: job.id },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { staticClass: "left-container-border", attrs: { span: 6 } },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { margin: "0px auto" },
                      attrs: {
                        width: "36",
                        height: "36",
                        viewBox: "0 0 36 36",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("circle", {
                        attrs: {
                          cx: "18",
                          cy: "18",
                          r: "18",
                          fill: _vm.statusColors(job).bcc,
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M26.3574 25.2773C26.3574 25.2773 24.6984 24.115 24.5363 24.046L26.5884 18.4518C26.7437 18.1931 26.6367 17.5619 25.94 17.5171H23.2809V12.5574C23.2809 12.3056 23.136 12.0539 22.8842 11.9469L18.7178 9.82581C18.5385 9.75338 18.2867 9.75338 18.1074 9.82581L13.5443 11.9469C13.2925 12.0539 13.1477 12.3056 13.1477 12.5954V17.5205H10.454C9.69868 17.5964 9.73662 18.2379 9.8056 18.4552L11.8888 24.1219C11.7267 24.1944 10.0677 25.4256 10.0677 25.4256C9.74352 25.6395 9.70903 26.0706 9.92287 26.3948C10.0677 26.5742 10.4368 26.8535 10.9265 26.5397L12.2889 25.4981C13.2684 24.9048 14.0479 25.5705 14.0479 25.5705C14.9791 26.2534 16.4518 26.2534 17.3865 25.5705C17.3865 25.5705 18.1211 24.9669 19.0731 25.536C20.0043 26.2189 21.477 26.2189 22.4117 25.536C22.4117 25.536 23.1705 24.829 24.2052 25.4291L25.5331 26.3983C25.8193 26.6121 26.2849 26.5776 26.5022 26.2534C26.7161 25.9602 26.6781 25.4946 26.3574 25.2773ZM18.9351 11.54L21.8461 13.0265V17.4826H18.9351V11.54ZM14.548 13.061L17.4934 11.6848V17.4826H14.548V13.061ZM21.5495 24.4185C21.1183 24.7427 20.3285 24.7427 19.8974 24.4185C18.9662 23.7356 17.5969 23.7253 16.5587 24.4185C16.5174 24.4461 15.662 25.0463 14.7653 24.384C14.3514 24.0805 13.834 23.9115 13.3029 23.877L11.4956 18.9174H24.9019L23.1084 23.8081C22.1737 23.8943 21.5495 24.4185 21.5495 24.4185Z",
                          fill: _vm.statusColors(job).text,
                        },
                      }),
                    ]
                  ),
                  _c("div", [
                    _c("span", { staticClass: "mid-font" }, [
                      _vm._v(" " + _vm._s(_vm.JOB_TYPE[job.type].filterText)),
                    ]),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "mid-font",
                        style: { color: _vm.statusColors(job).text },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.STATUS_SELECTION[job.status].title)
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { "padding-left": "8px" }, attrs: { span: 15 } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "padding-bottom": "3px",
                      },
                    },
                    [
                      _c("span", { staticClass: "icon-svg" }, [
                        _c("img", {
                          attrs: {
                            alt: "img",
                            src: require("@/assets/images/mobile/calendar.svg"),
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "small-font" }, [
                        _vm._v(
                          _vm._s(
                            job?.vesselEta
                              ? _vm
                                  .$moment(job?.vesselEta)
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : "-"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "padding-bottom": "3px",
                      },
                    },
                    [
                      _c("span", { staticClass: "icon-svg" }, [
                        _c("img", {
                          attrs: {
                            alt: "img",
                            src: require("@/assets/images/mobile/vessel.svg"),
                          },
                        }),
                      ]),
                      job?.type == 2
                        ? _c("span", { staticClass: "small-font" }, [
                            _vm._v(
                              _vm._s(
                                job.locationLevel1 ? job.locationLevel1 : "-"
                              )
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        { staticClass: "small-font", attrs: { "e-else": "" } },
                        [
                          _vm._v(
                            _vm._s(
                              job.vesselName
                                ? `${job.vesselName} / ${job.vesselImo}`
                                : "-"
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "padding-bottom": "3px",
                      },
                    },
                    [
                      _c("span", { staticClass: "icon-svg" }, [
                        _c("img", {
                          attrs: {
                            alt: "img",
                            src: require("@/assets/images/mobile/location.svg"),
                          },
                        }),
                      ]),
                      job?.type == 2
                        ? _c("span", { staticClass: "small-font" }, [
                            _vm._v(
                              _vm._s(
                                job?.floaterName
                                  ? job.floaterName
                                  : job.locationLevel1
                                  ? `${job.locationLevel1} / ${job.locationLevel2}`
                                  : "_"
                              )
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        { staticClass: "small-font", attrs: { "e-else": "" } },
                        [
                          _vm._v(
                            _vm._s(
                              job.locationLevel1
                                ? `${job.locationLevel1} / ${
                                    job.locationLevel2 ?? "-"
                                  }`
                                : "-"
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "padding-bottom": "3px",
                      },
                    },
                    [
                      _c("span", { staticClass: "icon-svg" }, [
                        _c("img", {
                          attrs: {
                            alt: "img",
                            src: require("@/assets/images/mobile/oil.svg"),
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "small-font" }, [
                        _vm._v(
                          _vm._s(job.productName) +
                            _vm._s(
                              job.finalQty
                                ? ` / ${job.finalQty} ${job.qtyUnitMetric}`
                                : job.loadingQty &&
                                    ` / ${job.loadingQty} ${job.qtyUnitMetric}`
                            )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm.hasTableMenu
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 1 },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-dropdown",
                            {
                              attrs: { trigger: "click" },
                              on: {
                                command: function ($event) {
                                  return _vm.handleCommand($event, job)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-more" }),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(_vm.actionList, function (item) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: item.key,
                                      attrs: {
                                        icon: item.icon,
                                        command: item.key,
                                      },
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }